import React, { useId } from 'react'
import Select from 'react-select'
import { Checkbox, type CheckboxProps } from './ui/checkbox.tsx'
import { Input } from './ui/input.tsx'
import { Label } from './ui/label.tsx'
import { Textarea } from './ui/textarea.tsx'
import { cva } from 'class-variance-authority'
import { cn } from '#app/utils/misc.tsx'
import { InputNoCustomClassNames } from '#app/components/ui/input-no-classnames.tsx'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { Link } from '@remix-run/react'
import { useInputControl } from '@conform-to/react'
import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from './ui/input-otp.tsx'
import { OTPInputProps, REGEXP_ONLY_DIGITS } from 'input-otp'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

const labelVariants = cva('text-muted-foreground text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70')

export function ErrorList({ errors }: { errors?: ListOfErrors }) {
	const errorsToRender = errors?.filter(Boolean)
	if (!errorsToRender?.length) return null
	return (
		<div className="mb-4 flex flex-col gap-1 text-center">
			{errorsToRender.map(e => (
				<div key={e} className="text-[14px] text-foreground-destructive">
					{e}
				</div>
			))}
		</div>
	)
}

export function ErrorBulletList({ id, errors }: { id?: string; errors?: ListOfErrors }) {
	const errorsToRender = errors?.filter(Boolean)
	if (!errorsToRender?.length) return null
	return (
		<ul id={id} className="flex flex-col gap-1 text-center">
			{errorsToRender.map(e => (
				<li key={e} className="text-[14px] text-foreground-destructive">
					{e}
				</li>
			))}
		</ul>
	)
}

export function OTPField({
	labelProps,
	inputProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: Partial<OTPInputProps & { render: never }>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<InputOTP pattern={REGEXP_ONLY_DIGITS} maxLength={6} id={id} aria-invalid={errorId ? true : undefined} aria-describedby={errorId} {...inputProps}>
				<InputOTPGroup>
					<InputOTPSlot index={0} />
					<InputOTPSlot index={1} />
					<InputOTPSlot index={2} />
				</InputOTPGroup>
				<InputOTPSeparator />
				<InputOTPGroup>
					<InputOTPSlot index={3} />
					<InputOTPSlot index={4} />
					<InputOTPSlot index={5} />
				</InputOTPGroup>
			</InputOTP>
			<div className="min-h-[32px] px-4 pb-3 pt-2">{errorId ? <ErrorBulletList id={errorId} errors={errors} /> : null}</div>
		</div>
	)
}

export function PasswordField({
	inputProps,
	errors,
	showPassword,
	togglePasswordVisibility,
	generateStrongPassword,
	createPassword,
	label,
}: {
	inputProps: React.InputHTMLAttributes<HTMLInputElement>
	errors?: ListOfErrors
	className?: string
	showPassword: boolean
	togglePasswordVisibility: any
	generateStrongPassword?: any
	createPassword?: boolean
	label?: string
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className="flex flex-col">
			<Label className="mb-1 block text-sm text-gray-700" htmlFor="password">
				{label ? label : 'password'}
			</Label>
			<div className="mb-1 block text-sm text-gray-500">{createPassword ? '(min 8 characters with uppercase letter and at least one number)' : ''}</div>
			<div className="relative flex items-center">
				<Input
					className="h-14 w-full rounded-lg border-gray-300 bg-gray-100 px-3 py-2 text-black shadow"
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					{...inputProps}
					type={showPassword ? 'text' : 'password'}
				/>
				<button type="button" onClick={togglePasswordVisibility} className="absolute right-3 text-gray-400 focus:outline-none">
					{showPassword ? <FaEyeSlash /> : <FaEye />}
				</button>
			</div>
			{createPassword ? (
				<div className="text-sm text-blue-400">
					<Link to={'#'} onClick={() => generateStrongPassword()}>
						suggest a strong password
					</Link>
				</div>
			) : (
				<div></div>
			)}
			<div className="min-h-[32px] px-4 pb-3 pt-1">{errorId ? <ErrorBulletList id={errorId} errors={errors} /> : null}</div>
		</div>
	)
}

export function DateField({
	labelProps,
	inputProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: React.InputHTMLAttributes<HTMLInputElement>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<Input id={id} type="date" aria-invalid={errorId ? true : undefined} aria-describedby={errorId} {...inputProps} />
			<div className="min-h-[32px] px-4 pb-3 pt-1">{errorId ? <ErrorBulletList id={errorId} errors={errors} /> : null}</div>
		</div>
	)
}

export function SelectField2({
	labelProps,
	selectProps,
	onChange,
	options,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	selectProps: any
	onChange?: any
	options: any
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = selectProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className="flex w-full flex-col space-y-2">
			<label className={cn(labelVariants(), className)} htmlFor={id} {...labelProps} />
			<select
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				onChange={onChange}
				{...selectProps}
				className="min-w-80 rounded-lg border border-gray-300 bg-white px-4 py-2 text-gray-700 focus:border-blue-500 focus:ring focus:ring-blue-200"
			>
				{options.length > 0 ? (
					options.map((option: any) => (
						// @ts-ignore
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					))
				) : (
					<option value="" disabled>
						No options available
					</option>
				)}
			</select>
			<div className="min-h-[32px] px-4 pb-3 pt-1">{errorId ? <ErrorBulletList id={errorId} errors={errors} /> : null}</div>
		</div>
	)
}

export function SelectField({
	labelProps,
	selectProps,
	onChange,
	options,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	selectProps: any
	onChange?: any
	options: any
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = selectProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<label className={cn(labelVariants(), className)} htmlFor={id} {...labelProps} />
			<Select
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				onChange={onChange}
				options={options}
				className="text-black"
				defaultValue={options}
				{...selectProps}
			></Select>
			<div className="min-h-[32px] px-4 pb-3 pt-1">{errorId ? <ErrorBulletList id={errorId} errors={errors} /> : null}</div>
		</div>
	)
}

export function SaveMessageField({
	id,
	labelProps,
	inputProps,
}: {
	id: string
	labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: React.InputHTMLAttributes<HTMLInputElement>
	errors?: ListOfErrors
}) {
	return (
		<>
			<Label htmlFor={id} {...labelProps} />
			<InputNoCustomClassNames id={id} aria-invalid={id ? true : undefined} aria-describedby={id} {...inputProps} />
		</>
	)
}

export function Field({
	labelProps,
	inputProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: React.InputHTMLAttributes<HTMLInputElement>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<Input id={id} aria-invalid={errorId ? true : undefined} aria-describedby={errorId} {...inputProps} />
			<div className="min-h-[32px] px-4 pb-3 pt-1">{errorId ? <ErrorBulletList id={errorId} errors={errors} /> : null}</div>
		</div>
	)
}

export function TextareaField({
	labelProps,
	textareaProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = textareaProps.id ?? textareaProps.name ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<Textarea id={id} aria-invalid={errorId ? true : undefined} aria-describedby={errorId} {...textareaProps} />
			<div className="min-h-[32px] px-4 pb-3 pt-1">{errorId ? <ErrorBulletList id={errorId} errors={errors} /> : null}</div>
		</div>
	)
}

export function CheckboxField({
	labelProps,
	buttonProps,
	errors,
	className,
	checkBoxButtonClassName,
}: {
	labelProps: JSX.IntrinsicElements['label']
	buttonProps: CheckboxProps & {
		name: string
		form: string
		value?: string
	}
	errors?: ListOfErrors
	className?: string
	checkBoxButtonClassName?: string
}) {
	const { key, defaultChecked, ...checkboxProps } = buttonProps
	const fallbackId = useId()
	const checkedValue = buttonProps.value ?? 'on'
	const input = useInputControl({
		key,
		name: buttonProps.name,
		formId: buttonProps.form,
		initialValue: defaultChecked ? checkedValue : undefined,
	})
	const id = buttonProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={className}>
			<div className="flex gap-2">
				<Checkbox
					{...checkboxProps}
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					checked={input.value === checkedValue}
					onCheckedChange={state => {
						input.change(state.valueOf() ? checkedValue : '')
						buttonProps.onCheckedChange?.(state)
					}}
					onFocus={event => {
						input.focus()
						buttonProps.onFocus?.(event)
					}}
					onBlur={event => {
						input.blur()
						buttonProps.onBlur?.(event)
					}}
					className={checkBoxButtonClassName}
					type="button"
				/>
				<label htmlFor={id} {...labelProps} className="self-center text-body-xs text-muted-foreground" />
			</div>
			<div className="px-4 pb-3 pt-1">{errorId ? <ErrorBulletList id={errorId} errors={errors} /> : null}</div>
		</div>
	)
}
